html,
body {
  margin: 0;
  color: #070f15;
  font-family: "Heebo";
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
      html,body {
        color: #070F15;
        font-family: 'Heebo';
        font-weight: normal;
      font-style: normal;
      }
      /* fonts start*/
      @font-face {
      font-family: 'Heebo';
      src: url('../public/fonts/Heebo-Light.eot');
      src: url('../public/fonts/Heebo-Light.eot?#iefix') format('embedded-opentype'),
          url('../public/fonts/Heebo-Light.woff2') format('woff2'),
          url('../public/fonts/Heebo-Light.woff') format('woff'),
          url('../public/fonts/Heebo-Light.ttf') format('truetype'),
          url('../public/fonts/Heebo-Light.svg#Heebo-Light') format('svg');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Heebo';
      src: url('../public/fonts/Heebo-ExtraBold.eot');
      src: url('../public/fonts/Heebo-ExtraBold.eot?#iefix') format('embedded-opentype'),
          url('../public/fonts/Heebo-ExtraBold.woff2') format('woff2'),
          url('../public/fonts/Heebo-ExtraBold.woff') format('woff'),
          url('../public/fonts/Heebo-ExtraBold.ttf') format('truetype'),
          url('../public/fonts/Heebo-ExtraBold.svg#Heebo-ExtraBold') format('svg');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Heebo';
      src: url('../public/fonts/Heebo-Regular.eot');
      src: url('../public/fonts/Heebo-Regular.eot?#iefix') format('embedded-opentype'),
          url('../public/fonts/Heebo-Regular.woff2') format('woff2'),
          url('../public/fonts/Heebo-Regular.woff') format('woff'),
          url('../public/fonts/Heebo-Regular.ttf') format('truetype'),
          url('../public/fonts/Heebo-Regular.svg#Heebo-Regular') format('svg');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Heebo';
      src: url('../public/fonts/Heebo-ExtraLight.eot');
      src: url('../public/fonts/Heebo-ExtraLight.eot?#iefix') format('embedded-opentype'),
          url('../public/fonts/Heebo-ExtraLight.woff2') format('woff2'),
          url('../public/fonts/Heebo-ExtraLight.woff') format('woff'),
          url('../public/fonts/Heebo-ExtraLight.ttf') format('truetype'),
          url('../public/fonts/Heebo-ExtraLight.svg#Heebo-ExtraLight') format('svg');
      font-weight: 200;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Heebo';
      src: url('../public/fonts/Heebo-Medium.eot');
      src: url('../public/fonts/Heebo-Medium.eot?#iefix') format('embedded-opentype'),
          url('../public/fonts/Heebo-Medium.woff2') format('woff2'),
          url('../public/fonts/Heebo-Medium.woff') format('woff'),
          url('../public/fonts/Heebo-Medium.ttf') format('truetype'),
          url('../public/fonts/Heebo-Medium.svg#Heebo-Medium') format('svg');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Heebo';
      src: url('../public/fonts/Heebo-Bold.eot');
      src: url('../public/fonts/Heebo-Bold.eot?#iefix') format('embedded-opentype'),
          url('../public/fonts/Heebo-Bold.woff2') format('woff2'),
          url('../public/fonts/Heebo-Bold.woff') format('woff'),
          url('../public/fonts/Heebo-Bold.ttf') format('truetype'),
          url('../public/fonts/Heebo-Bold.svg#Heebo-Bold') format('svg');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }
  
  @font-face {
      font-family: 'Heebo';
      src: url('../public/fonts/Heebo-SemiBold.eot');
      src: url('../public/fonts/Heebo-SemiBold.eot?#iefix') format('embedded-opentype'),
          url('../public/fonts/Heebo-SemiBold.woff2') format('woff2'),
          url('../public/fonts/Heebo-SemiBold.woff') format('woff'),
          url('../public/fonts/Heebo-SemiBold.ttf') format('truetype'),
          url('../public/fonts/Heebo-SemiBold.svg#Heebo-SemiBold') format('svg');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
  }
  .fixed-top.scrolled {
  background-color: #fff !important;
  transition: background-color 100ms linear;
  box-shadow: 0px 3px 5px #0000000D;
  color:#070F15!important;
}


::-webkit-file-upload-button {
  cursor: pointer;
}

.font_18 {
  font-size: 18px;
}

.font_14 {
  font-size: 14px;
}

.bg-greyy {
  background-color: #f8f9fe;
}

.py_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.font_regular {
  font-weight: normal;
  font-style: normal;
}

button.btn {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: #070f15;
}

div.contentbg11 button.btn.btn-outline-light {
  color: #fff !important;
}

.font_medium {
  font-weight: 500;
  font-style: normal;
}

.font_light {
  font-weight: 300;
  font-style: normal;
}

div.contentbg11 button.btn {
  color: #fff !important;
}

.font_16 {
  font-size: 16px;
}

.font_36 {
  font-size: 36px;
  font-weight: 500;
}
.font_40 {
  font-size: 40px;
  font-weight: 500;
}

/* .dropdown-toggle::after {
  content: url(../public/arrow-down_dpn.svg) !important;
  vertical-align: initial;
  border: 0px !important;
  position: absolute;
  top: 5px;
} */

/* .fixed-top.scrolled .dropdown-toggle::after {
  content: url(../public/arrow-down_dpn.svg) !important;
} */

.jumbonavbararrow .dropdown-toggle::after {
  content: url(./Images/arrow_down_white_icon.svg) !important;
  vertical-align: initial;
  border: 0px !important;
  position: absolute;
  top: 5px;
}

.navbar-light .navbar-nav .nav-link {
  color: #070f15 !important;
}

.jumbonavbararrow .navbar-light .navbar-nav .nav-link {
  color: #fff !important;
}

.jumbonavbararrow .scrolled.navbar-light .navbar-nav .nav-link {
  color: #070f15 !important;
}

.navbar .btn-secondary {
  background: transparent;
  color: #070f15;
}

.navbar .btn-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.owl-carousel .owl-item {
  min-height: 300px !important;
}

.owl-theme .owl-nav {
  position: absolute;
  top: -85px;
  right: 0px;
  border: 1px solid #707070;
  border-radius: 8px;
  line-height: 12px;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  margin: 0px;
}

.owl-theme .owl-nav [class*="owl-"] {
  background: transparent !important;
  color: #070f15 !important;
  padding: 7px 7px 8px 7px !important;
  font-size: 36px !important;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent !important;
  color: #070f15 !important;
}

.pleft4 {
  padding-left: 15px;
}

/* media queries */
@media only screen and (max-width: 767px) {
  .container {
    padding: 0px 20px !important;
  }

  .mobilespacingt {
    padding-top: 10px !important;
    margin-top: 10px !important;
  }

  .font_36 {
    font-size: 26px !important;
  }

  .mobilemtsmall {
    margin-top: 20px !important;
  }

  .font_14 {
    font-size: 16px !important;
  }

  #navbarScroll {
    background: #fff;
    margin: 20px -20px 0px -20px;
    box-shadow: 2px 2px 9px 3px #ccc;
    padding: 15px 0;
  }

  .linee_bg {
    display: none
  }

  div.mobile_flex_column {
    flex-direction: column !important;
  }

  .px_mobile_0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  div.mobile_pad_left {
    padding-right: calc(var(--bs-gutter-x) * .5) !important;
    padding-left: calc(var(--bs-gutter-x) * .5) !important;
  }

  .EventMainBannerCarouselDiv {
    width: 100% !important;
    top: 50px !important;
  }

  div.modal_image_main.slick-slider .slick-prev {
    z-index: 99;
    left: 6px !important;
  }

  .jumbotxt {
    font-size: 35px !important;
    margin-top: 20px;
  }

  .aboutdevportal_Carousalmain {
    width: 100% !important;
  }

  .mb_mobile_30 {
    margin-bottom: 20px !important;
  }

  .pb_mobile_30 {
    padding-bottom: 20px !important;
  }

  .EntryScreen_main {
    padding-top: 220px !important;
  }

  div.mobile_mt_20 {
    margin-top: 20px;
  }

  div.SingleProduct_rightmain {
    position: sticky !important;
    top: 1rem !important;
    overflow: visible !important;
    height: auto !important;
  }

  .EntryScreen_main .SingleProduct_rightmain {
    position: sticky !important;
    top: 1rem !important;
    overflow: visible !important;
    height: auto !important;
    padding-bottom: 40px;
  }

  div.top_0px {
    top: 0px !important;
  }

  .rapidx_landingscreen {
    background-position-y: 0 !important;
    background-color: #f0f6ff !important;
  }

  .font_55 {
    font-size: 35px !important;
  }

  .font_40 {
    font-size: 35px !important;
  }

  .mobile_pad_leftt {
    padding-right: calc(var(--bs-gutter-x) * .5) !important;
    padding-left: calc(var(--bs-gutter-x) * .5) !important;
    margin-right: 0px !important;
  }

  .red_rect {
    position: absolute;
    top: 34% !important;
    left: 9% !important;
  }

  .red_rect img {
    width: 50% !important;
  }

  .grey_triangle {
    left: 4% !important;
  }
  .grey_triangle img {
    margin-top: 70px;
  }

  .ml_13minus {
    margin-left: inherit !important;
  }

  .Slider_rapidx_div .slick-next {
    right: 0% !important;
  }

  .Slider_rapidx_div .slick-prev {
    left: 0% !important;
  }

  .Slider_rapidx_innerdiv p {
    padding: 0px 40px !important;
    font-size: 16px !important;
  }

  .pt_130 {
    padding-top: 50px !important;
  }

  .mobile_pt_50 {
    padding-top: 50px;
  }

  .pt_120 {
    padding-top: 50px !important;
  }

  .pt_170 {
    padding-top: 50px !important;
  }

  .font_42 {
    font-size: 20px !important;
  }

  .py_60 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

}
@media only screen and (max-width: 991px){
#navbarScroll {
    background: #fff;
    margin: 20px -20px 0px -20px;
    box-shadow: 2px 2px 9px 3px #ccc;
    padding: 15px 0;
}
.profile_icon_div{
  top: 0% !important;;
}
.desktop_progress_align{
 display: none;
}
div.mobile_pad_right{
  padding-right: calc(var(--bs-gutter-x) * .5)!important;
}
div.mobile_flex_column{
  flex-direction: column;
}
div.mobile_flex_ms_left{
  margin-left: inherit!important;
}
}


/* Responsive css ends for mobile potraid */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.footer-social-list li {
  color: #fff;
  margin-left: 15px;
  border-right: 1px solid #fff;
  padding-right: 15px;
  margin-bottom: 0px;
}

.footer-social-list li:last-child {
  border: none;
  padding-right: 0px;
}

.footer-social-list {
  margin-bottom: 0px;
  padding: 0px;
}

.footer-social-list li a {
  color: #fff;
  font-size: 15px;
  font-weight: medium;
  font-style: medium;
  text-decoration: none;
}

.ptop100 {
  padding-top: 140px;
  padding-bottom: 20px;
}

.ptop170 {
  padding-top: 150px;
  padding-bottom: 80px;
}

.font_30 {
  font-size: 30px;
}

.font_45 {
  font-size: 45px;
}

.customig {
  height: 50px;
  margin: 20px 0;
  background: transparent;
  color: #fff;
  border: 1px solid #fffdfd;
  border-radius: 25px;
}

.customig input {
  background: transparent;
  color: #fff;
  border: 0px;
  border-radius: 25px;
  font-size: 15px;
}

.customig input:focus {
  background: transparent;
  color: #fff;
  box-shadow: none;
}

.jumbonavbararrow input::placeholder {
  color: #fff;
  opacity: 1;
}

.customig .input-group-text {
  border: 0px;
  padding: 0px 20px;
  background: transparent;
  border-radius: 25px;
}

.jumbonavbararrow .customm_searchdiv input {
  color: #fff;
}

.jumbonavbararrow .customm_searchdiv input:focus {
  color: #fff;
}

.jumbonavbararrow .navbar .btn-secondary {
  color: #fff !important;
  border: 1px solid #fff !important;
}

.jumbonavbararrow .navbar.scrolled .btn-secondary {
  background: transparent;
  color: #070f15 !important;
  border-color: #6c757d !important;
}

.cusearchdiv {
  position: relative;
}

.cusearchdiv input {
  background: transparent;
  padding-inline-end: 25px;
}

/* .cusearchdiv::after {
  position: absolute;
  content: url("./app/common/images/Icon_feather_search.svg");
  right: 10px;
  top: 9px;
} */

.jumbonavbararrow .cusearchdiv::after {
  content: url("./Images/icon_search_white.svg") !important;
}

/* .jumbonavbararrow .navbar.scrolled .cusearchdiv::after {
  content: url("./app/common/images/Icon_feather_search.svg") !important;
} */

.jumbonavbararrow .cusearchdiv input::placeholder {
  color: #fff;
  opacity: 1;
  /* Firefox */
}

.jumbonavbararrow .navbar.scrolled .cusearchdiv input::placeholder {
  color: #070f15;
  opacity: 1;
  /* Firefox */
}

.font_20 {
  font-size: 20px;
}

.accordiancustommain .accordion-item {
  border: 0px !important;
}

.accordiancustommain .accordion-item .accordion-body {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 15px;
  padding: 0px;
  word-break: break-word;
}

.accordiancustommain .accordion-item .accordion-button {
  background: transparent !important;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: #070f15;
  padding-right: 0px;
  padding-left: 0px;
}

.accordiancustommain .accordion-item .accordion-button:focus {
  box-shadow: none;
}

.accordiancustommain .accordion-item .accordion-button:not(.collapsed) {
  box-shadow: inset 0 -1px 0 rgb(112 112 122 / 13%);
}

.btnbadgemain {
  position: relative !important;
  padding: 0px 45px 1px 10px !important;
  background: #fff;
  border: 1px solid #b9b9b9 !important;
  border-radius: 5px !important;
  color: #7b7b7b !important;
  font-size: 13px !important;
  margin-right: 10px;
  margin-bottom: 10px;
}

.btn-default.btnbadgemain:focus {
  box-shadow: none !important;
}

.btnbadgeinner {
  position: absolute !important;
  top: 0px !important;
  bottom: 0px;
  right: 0px;
  color: #000 !important;
  border-left: 1px solid #ccc;
  border-radius: 0px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.color_grey {
  color: #070f15;
}

.navLinkcustom .nav-link {
  display: inline-block;
  padding: 0px;
}

.btn.btn-yellow {
  background: #fcc446;
  border-radius: 5px;
  color: #070f15;
  padding: 12px 20px;
  border: 1px solid #fcc446 !important;
}

.btn.btn-yellow:hover {
  background: #fff;
  border-radius: 5px;
  color: #070f15;
  border: 1px solid #070f15 !important;
}

.btn-outlined-secondaryy {
  background: transparent;
  border: 1px solid #fffdfd !important;
  border-radius: 5px;
  color: #fff !important;
  padding: 12px 20px !important;
  margin-left: 15px;
}

.btn-outlined-secondaryy:hover {
  background: #fcc446;
  border: 1px solid #fcc446 !important;
  border-radius: 5px;
  color: #070f15 !important;
  padding: 12px 20px !important;
  margin-left: 15px;
}

/* responsive css starts */
@media (min-width: 1200px) {
  .container {
    max-width: 1270px !important;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px !important;
  }
}

/* responsive css ends */
.breadcrumb-item.active a {
  text-decoration: none;
}

.breadcrumb-item {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #919191;
  text-decoration: none;
}

.accordiancustommain.singleproductmain .accordion-item .accordion-button {
  font-size: 24px !important;
  font-weight: 300;
  font-style: normal;
  padding-bottom: 10px;
}

.accordiancustommain.singleproductmain .accordion-item .accordion-button:not(.collapsed) {
  box-shadow: none;
  padding-bottom: 10px;
}

.hashtext {
  color: #005aab;
  font-weight: bold;
  padding-right: 10px;
  font-size: 28px !important;
}

.accordiancustommain.singleproductmain .accordion-item .accordion-body {
  max-height: initial !important;
}

.singleaccordianp {
  font-size: 15px;
}

.benifitsdivmain {
  border: 1px solid #eef0f4;
  border-radius: 10px;
  padding: 22px 0;
  height: 100%;
}

.benifitsdivmain .yellowcolor {
  border-left: 2px solid #fcb61a;
}

.benifitsdivmain .bluecolor {
  border-left: 2px solid #075ba9;
}

.benifitsdivmain .orangecolor {
  border-left: 2px solid #e92c2f;
}

.benifitsdivmain .pinkcolor {
  border-left: 2px solid #cb6eb2;
}

.benifitsdivmain .lightgreencolor {
  border-left: 2px solid #6eb565;
}

.benifitsdivmain .magentacolor {
  border-left: 2px solid #565ac7;
}

.benifitsdivmain1 {
  padding: 22px 0;
  border: 1px solid #eef0f4;
  border-radius: 10px;
  background: #f8f8f8;
  height: 100%;
}

.singleaccordianpul li {
  font-size: 15px;
  list-style: disc inside !important;
  padding-left: 0px;
}

.hrline {
  background-color: #707070;
}

.MuiTablePagination-selectLabel {
  margin-top: 12px !important
}

.MuiTablePagination-displayedRows {
  margin-top: 12px !important
}

button.btn-primary1 {
  background: #54D0ED;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  padding: 3px 12px;
  margin-left: 10px;
  border-radius: 5px;
  border-color: #54D0ED;
}

button.btn-success1 {
  background: #7AD18E;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  padding: 3px 12px;
  margin-left: 10px;
  border-radius: 5px;
  border-color: #7AD18E;
}

button.btn-danger1 {
  background: #FA7848;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  padding: 3px 12px;
  margin-left: 10px;
  border-radius: 5px;
  border-color: #FA7848;
}

.sqdiv button.btn-success {
  background: #4bc273;
  border-radius: 3px;
  padding: 1px 12px;
  border-color: #4bc273;
}

.sqdiv button.btn-danger {
  background: #d41f63;
  border-radius: 3px;
  padding: 1px 12px;
  border-color: #d41f63;
}

.forumchatright {
  border-bottom: 1px solid #ddd;
}

.innerchatdiv {
  padding-left: 10%;
  padding-top: 30px;
}

.replybtnn {
  display: inline-block !important;
  cursor: pointer;
}

.textareaheight {
  height: 170px !important;
  resize: none;
  border: 1px solid #e6e6e6 !important;
  font-size: 14px;
}

.wycomment .form-control {
  border: 1px solid #e6e6e6 !important;
  height: 45px;
  font-size: 14px;
}

.wycomment button.btn-primary {
  color: #fff;
  background: #005aab;
  border: 1px solid #005aab;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  padding: 11px 25px;
}

.carousal_maindiv1 {
  width: 100%;
  border-radius: 10px;
}

.image_45 {
  width: 45px!important;
  height: 45px!important;
}

.carousalDivmain {
  position: relative;
}

.carousalDivmain .owl-theme .owl-nav {
  position: static !important;
  border: 0px;
}

.carousalDivmain .owl-theme .owl-nav button.owl-prev {
  position: absolute;
  top: 40%;
  left: 2px;
  background: #fff !important;
  border-radius: 100%;
  padding: 10px 15px !important;
  z-index: 9999;
  box-shadow: 0px 10px 15px #00000029;
  width: 62px;
  height: 62px;
}

.carousalDivmain .owl-theme .owl-nav button.owl-next:hover,
.carousalDivmain .owl-theme .owl-nav button.owl-prev:hover {
  background: #fcb61a !important;
  color: #fff !important;
}

.carousalDivmain .owl-theme .owl-nav button.owl-next {
  position: absolute;
  top: 40%;
  right: 2px;
  background: #fff !important;
  border-radius: 100%;
  padding: 10px 15px !important;
  z-index: 9999;
  box-shadow: 0px 10px 15px #00000029;
  width: 62px;
  height: 62px;
}

.carousalDivmain .owl-theme .owl-dots .owl-dot span {
  border: 1px solid #707070;
  background: #fff;
  width: 12px;
  height: 12px;
  margin: 5px 5px;
}

.carousalDivmain .owl-theme .owl-dots .owl-dot.active span,
.carousalDivmain .owl-theme .owl-dots .owl-dot:hover span {
  border: 1px solid #fcb61a;
  background: #fcb61a;
  width: 12px;
  height: 12px;
}

.carousalDivmain .owl-theme .owl-dots {
  margin-top: 30px;
}

.carousalDivmain.review {
  border: 1px solid #eef0f4;
  padding: 25px;
  border-radius: 20px;
}

.carousalDivmain.review .owl-carousel .owl-item {
  min-height: initial !important;
}

.fillstars {
  color: orange;
  fill: orange;
}

.relatedTopicmain {
  background: #f4f4f4;
  padding: 25px;
  border-radius: 20px;
}

.listgroupitems li {
  list-style: none;
  margin-bottom: 11px;
}

.rightnav_items li {
  display: block;
  border-left: 2px solid #e2e2e2;
  margin-bottom: 0px;
  padding: 10px 10px 10px 30px;
}

.rightnav_items {
  padding: 0px;
}

.rightnav_items li.active {
  border-left: 2px solid #005aab;
}

.filetype button.btn-default {
  background: #eef0f4;
  color: #070f15;
  padding: 1px 6px;
  border-radius: 5px;
  font-size: 12px;
}


.progressdiv_main {
  background: #eef0f4;
  padding: 16px 0px 15px 0;
  z-index: 999;
}

.progressdiv_main .progress {
  background: #d7ead3;
  height: 10px;
}

.progressdiv_main .progress .progress-bar {
  background-color: #72b266;
}

.progressdivtext {
  font-size: 14px;
}

button.btn-custom-primary-small {
  color: #fff !important;
  background: #005aab;
  border: 1px solid #005aab;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  padding: 8px 25px 6px 25px;
  margin-left: 10px;
  border-radius: 5px;
}

button.btn-custom-secondary-small {
  color: #070f15;
  background: transparent;
  border: 1px solid #848484;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  padding: 8px 25px 6px 25px;
  border-radius: 5px;
}
button.btn-custom-warning-small {
  color: #FFFFFF;
  background: #FCB61A;
  border: 1px solid #848484;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  padding: 8px 25px 6px 25px;
  border-radius: 5px;
}
.py-60 {
  padding: 40px 0px 60px 0;
}

.accordiancustommain.singleproductmain.entryscreen .accordion-button::after {
  background-image: url(../src/Images/edit_close.svg);
}

.accordiancustommain.singleproductmain.entryscreen .accordion-item .accordion-button:not(.collapsed)::after {
  background-image: url(../src/Images/edit_open.svg);
}

.carousalimagecontainer {
  position: relative;
  height: 175px;
  background-size: 100% 100%;
  text-align: center;
  border: 1px solid #eef0f4;
  border-radius: 5px;
}

.carousalimagecontainer img {
  height: 175px;
  padding: 10px;
}

.carousaladdcontainer {
  height: 175px;
  border-radius: 10px;
  border: 1px solid #eef0f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0px 15px;
  margin-top: 10px;
}

.carousaladdcontainer p {
  color: #8d8d8d;
  margin-top: 10px;
}

.benefitcontainerAdd p {
  color: #8d8d8d;
  margin-top: 10px;
}

.deleteimageicon {
  position: absolute;
  top: 42%;
  left: 44%;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.accordiancustommain.singleproductmain.entryscreen .accordion-item .accordion-body {
  padding-bottom: 20px;
  border-bottom: 1px solid #e2e2e2 !important;
}

.accordiancustommain.singleproductmain.entryscreen .accordion-item .accordion-button.collapsed {
  border-bottom: 1px solid #e2e2e2 !important;
}

.benefitcontainer {
  min-height: 175px;
  border-radius: 10px;
  border: 1px solid #eef0f4;
  padding: 20px;
}

.editicons svg {
  cursor: pointer;
}

.likeDisLike{
  cursor: pointer;
}
.benefitcontainerAdd {
  height: 100%;
  border-radius: 10px;
  border: 1px solid #eef0f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-label,
.form-control {
  font-size: 14px !important;
  font-weight: normal !important;
  font-style: normal !important;
}

.form-control {
  padding: 11px !important;
}

.addfeaturebtn {
  cursor: pointer;
  color: #005aab;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
}

.SingleProduct_rightmain {
  position: sticky;
  top: 5rem;
  right: 0;
  z-index: 2;
}

.EntryScreen_main .SingleProduct_rightmain {
  position: sticky;
  top: 10rem !important;
}

.SingleProduct_rightmain .form-check-label {
  margin-left: 10px;
}

.addbtnclass {
  width: 18px;
  height: 18px;
}

.btncloraddtext {
  color: #8d8d8d;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
}

.entryscreenulheight {
  max-height: 175px;
  overflow-y: auto;
  padding-bottom: 5px;
}

.inputaddon_custom .form-control {
  padding: 9px 50px 8px 15px !important;
}

.inputaddon_custom {
  position: relative;
}

.inputaddon_custom .Tickbtn {
  position: absolute;
  width: 22px;
  height: 22px;
  background: #005aab;
  color: #fff;
  border-radius: 100%;
  top: 9px;
  right: 7px;
  cursor: pointer;
  display: flex;
}

.avatardropdown .dropdown-toggle::after {
  top: 17px;
  right: -13px;
}

.avatardropdown .dropdown-menu a.dropdown-item,
.dropdown-menu a.dropdown-item {
  font-size: 14px !important;
  font-weight: normal;
  font-style: normal;
}

.welcometext {
  color: #aaaaaa;
  line-height: 15px;
}

.usertext {
  color: #232a2f;
  line-height: 15px;
}

.font_15 {
  font-size: 15px;
}

.font_13 {
  font-size: 13px;
}

.tags-input {
  display: inline-flex;
  margin: 0 auto;
  padding: 3px 5px;
  width: 100%;
  font-size: 14px;
  border: #cecece solid 1px;
  border-radius: 3px;
  flex-wrap: wrap;
}

.tag {
  margin: 3px;
}

.tags-input input {
  width: 100px;
  border: none;
  outline: none;
  height: 25px;
}

.tag {
  display: flex;
  padding: 4px 7px 3px 7px;
  background-color: #eef0f4;
  border-radius: 5px;
  align-items: center;
  font-size: 12px;
}

button.delete {
  display: inline-block;
  margin-left: 5px;
  padding: 0;
  width: 15px;
  height: 15px;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 8px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50px;
  border: none;
  outline: none;
  user-select: none;
}

button.delete::after {
  content: "X";
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
}

.help {
  font-size: 12px;
  color: #555;
  display: none;
}

.modal .modal-header .btn-close {
  width: 15px;
  height: 15px;
}

.font_12 {
  font-size: 12px;
}

.submitquestionlink {
  color: #005aab;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}

.submitquestionnone {
  color: #005aab;
  text-decoration: underline;
  font-weight: 500;
  pointer-events: none;
}

.required .form-label:after {
  content: "*";
  color: red;
  margin-left: 1px;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  padding: 0px 15px;
}

/* Example Styles for React Tags*/
#app {
  padding: 40px;
}

div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
  cursor: pointer;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 45px;
  margin: 0;
  font-size: 14px;
  width: 100%;
  border: 1px solid #eee;
  padding: 0 4px;
  cursor: pointer;
  width: 250px;
}

/* Styles for selected tags */
div.ReactTags__selected .ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  cursor: default !important;
  border-radius: 2px;
}

div.ReactTags__selected .ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
  border: none;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}

div.ReactTags__suggestions ul {
  list-style-type: none;
  background: white;
  width: 200px;
}

div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

div.ReactTags__suggestions li mark {
  background: none;
  font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.font_20 {
  font-size: 20px;
}

.pt-90 {
  padding-top: 120px;
}

#solutionOverview,
#customerProblem,
#image,
#benefits,
#features,
#uniqueness,
#aboutReusability,
#commentsscroll {
  scroll-margin-top: 70px;
}

.rightnav_items a.nav-link {
  border-left: 1px solid #d2d2d2;
  padding-left: 15px !important;
}

.rightnav_items a.nav-link.active {
  border-left: 2px solid #005aab;
}

.cursor-pointer {
  cursor: pointer !important;
}

.navigation .pagination .page-link.active {
  background: #075ba9;
  color: #fff;
}

.page-link.active {
  background: #075ba9;
  color: #fff;
  border-left: 0px;
  border-right: 0px;
}

.page-link.active:focus {
  background: #075ba9;
  color: #fff;
  border-left: 0px;
  outline: none;
  box-shadow: none;
  border-right: 0px;
}

.rightnav_items li.is-current {
  border-left: 2px solid #005aab;
}

.SingleProduct_rightmain .rightnav_items li a {
  color: #070f15;
  text-decoration: none;
}

.rightnav_items ul {
  padding: 0px;
}

.ptop9030 {
  padding-top: 90px;
  padding-bottom: 30px;
}

.padtop7030 {
  padding-top: 70px;
  padding-bottom: 0px;
}

.FaqAccordian_leftMain {
  position: sticky;
  top: 5rem;
  right: 0;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;
}

#FAQ_One,
#FAQ_Two,
#FAQ_Three,
#FAQ_Four,
#FAQ_Five {
  scroll-margin-top: 60px;
}

.accordiancustommain.singleproductmain.faq .accordion-item {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc !important;
}

.rightnav_items li.is-current a {
  color: #005aab;
  font-weight: bold;
  font-size: 14px;
}

.FaqAccordian_leftMain .rightnav_items li a {
  color: #070f15;
  text-decoration: none;
  cursor: pointer;
}

.FaqAccordian_leftMain .rightnav_items li.is-current a {
  color: #005aab;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
}

.accordion-body .Q_A {
  border-bottom: 1px solid #dddddd;
}

.form-control.Input {
  height: 50px;
  border-radius: 25px;
  border: 0px;
  padding-left: 20px;
}

.Input.SearchRed {
  border: 1px solid red;
}

.SearchRed {
  border: 1px solid red;
}

.autocomplete.SearchRed {
  border-bottom-left-radius: 25px !important;
  border-top-left-radius: 25px !important;
}

.readmore_div {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.rec-item-wrapper {
  padding: 0px 15px !important;
}

.rec-pagination {
  display: none !important;
}

.error-label {
  font-size: 12px !important;
  font-weight: normal !important;
  font-style: normal !important;
  color: red;
}

.rec-pagination {
  display: none !important;
}

.rec-arrow {
  position: absolute;
}

.rec-arrow-left {
  top: -80px;
  right: 60px;
  border: 1px solid #707070 !important;
  border-radius: 0px !important;
  background: transparent !important;
  box-shadow: none !important;
  border-right: 0px !important;
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  line-height: 40px !important;
  font-size: 1.4rem !important;
}

.rec-arrow-right {
  top: -80px;
  right: 20px;
  border: 1px solid #707070 !important;
  border-radius: 0px !important;
  background: transparent !important;
  box-shadow: none !important;
  border-left: 0px !important;
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  line-height: 40px !important;
  font-size: 1.4rem !important;
}

.rec-arrow-left:hover,
.rec-arrow-right:hover {
  color: #333 !important;
}

.rec-carousel {
  position: relative;
}

.rec-item-wrapper {
  height: 280px;
}

.Newsheader {
  font-size: 24px;
  position: relative;
}

.border_style {
  border: 1px solid #dee2e6;
  height: 1px;
  flex: 1;
  margin-left: 10px;
  align-items: center;
  background: #dee2e6;
}

.border_style_1 {
  border: 1px solid #dee2e6;
  height: 1px;
  flex: 1;
  margin-right: 10px;
  align-items: center;
  background: #dee2e6;
}

.top_titles:hover .border_style,
.top_titles:hover .border_style_1 {
  border: 1px solid #005AAB;
  background: #005AAB;
}

.newsTitle {
  cursor: pointer;
}

.Newscontainer p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jumbotxt {
  font-size: 46px;
  font-weight: 500;
  font-style: normal;
}

.pdf-button {
  border: none;
  background-color: transparent;
  outline: none;
}

.thumbnail-image.smallsize {
  width: 45px;
  height: 45px;
}

.carousal_bg_container {
  text-align: center;
  padding: 35px 25px;
  background-image: url("./Images/carousal_bg_devportal.png");
  background-size: 100% 100%;
  background-position-x: 7px;
  background-position-y: 15px;
  background-repeat: no-repeat;
  height: 456px;
}

.aboutdevportal_Carousalmain {
  box-shadow: 0px 5px 20px #00000029;
  position: relative;
}

.pstyle {
  text-align: right;
  padding-top: -1px;
}

.ptop9090 {
  padding-top: 90px;
  padding-bottom: 150px;
}

.aboutdevportal_Carousalmain .rec-arrow {
  display: none;
}

.aboutdevportal_Carousalmain .rec-item-wrapper {
  height: 100% !important;
  padding: 0px !important;
}

.aboutdevportal_Carousalmain .rec-pagination {
  display: block !important;
  position: absolute;
  top: 0px;
  width: 100%;
  display: flex !important;
  margin-top: 0px;
}

.aboutdevportal_Carousalmain .rec-pagination .rec-dot {
  background: #e2e2e2 !important;
  flex: 1;
  border-radius: 0px !important;
  margin-top: 0px;
  height: 4px;
  box-shadow: none;
  margin-right: 0px;
}

.aboutdevportal_Carousalmain .rec-pagination .rec-dot:first-child {
  margin-left: 0px;
}

.aboutdevportal_Carousalmain .rec-pagination .rec-dot:last-child {
  margin-right: 0px;
}

.aboutdevportal_Carousalmain .rec-slider-container {
  margin: 0px;
}

.aboutdevportal_Carousalmain .rec-pagination .rec-dot.rec-dot_active {
  box-shadow: none;
  background: #fcb61a !important;
}

.aboutdevportal_Carousalmain {
  width: 400px;
  margin: 0px auto;
}




.SingleProduct_rightmain::-webkit-scrollbar,
.embed_placeholder::-webkit-scrollbar,
.explanation_div_height::-webkit-scrollbar,
.generate_div_height::-webkit-scrollbar,
.entryscreenulheight::-webkit-scrollbar,
.accordiancustommain .accordion-item .accordion-body::-webkit-scrollbar,
body::-webkit-scrollbar,
.webchat__basic-transcript__scrollable::-webkit-scrollbar {
  width: 6px;
}

.SingleProduct_rightmain::-webkit-scrollbar-thumb,
.embed_placeholder::-webkit-scrollbar-thumb,
.explanation_div_height::-webkit-scrollbar-thumb,
.generate_div_height::-webkit-scrollbar-thumb,
.entryscreenulheight::-webkit-scrollbar-thumb,
.accordiancustommain .accordion-item .accordion-body::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
.webchat__basic-transcript__scrollable::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background-color: #ccc;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.SingleProduct_rightmain::-webkit-scrollbar-track,
.embed_placeholder::-webkit-scrollbar-track,
.explanation_div_height::-webkit-scrollbar-track,
.generate_div_height::-webkit-scrollbar-track,
.entryscreenulheight::-webkit-scrollbar-track,
.accordiancustommain .accordion-item .accordion-body::-webkit-scrollbar-track,
body::-webkit-scrollbar-track,
.webchat__basic-transcript__scrollable::-webkit-scrollbar-track
 {
  border-radius: 10px;
  background-color: #f2f2f2;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.SingleProduct_rightmain::-moz-scrollbar {
  width: 8px;
}

.SingleProduct_rightmain::-moz-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2196f3;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.SingleProduct_rightmain::-moz-scrollbar-track {
  border-radius: 10px;
  background-color: #d5f7f7;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}

a.commentsscrolltext {
  color: #070f15;
  text-decoration: none;
}

.line_height_28 {
  line-height: 28px;
}

.aboutdevportal_Carousalmain .carousel-dots li {
  position: relative;
  display: inline-block;
  flex: 1;
  margin: 0px;
  background: #e2e2e2;
  margin-right: 4px;
  height: 5px;
}

.aboutdevportal_Carousalmain .carousel-dots {
  display: flex !important;
  display: block;
  position: absolute;
  top: 0px;
  margin: 0px;
  justify-content: space-evenly;
}

.aboutdevportal_Carousalmain .carousel-dots li.carousel-dots-active {
  background: #fcb61a;
}

.aboutdevportal_Carousalmain .carousel-dots li button {
  display: none;
}

.aboutdevportal_Carousalmain .carousel-dots li:last-child {
  margin-right: 0px;
}

/* RS-----LOADER */
.rs-loader {
  display: inline-block;
}

.rs-loader::before,
.rs-loader::after {
  content: " ";
  display: table;
}

.rs-loader::after {
  clear: both;
}

.rs-loader .rs-loader-spin {
  width: 18px;
  height: 18px;
}

.rs-loader .rs-loader-spin::before,
.rs-loader .rs-loader-spin::after {
  width: 18px;
  height: 18px;
}

.rs-loader .rs-loader-content {
  font-size: 12px;
  line-height: 1.5;
}

.rs-loader .rs-loader-vertical .rs-loader .rs-loader {
  height: 48px;
}

.rs-loader .rs-loader-vertical .rs-loader .rs-loader-content {
  line-height: 1.66666667;
}

.rs-loader-spin {
  position: relative;
  display: inline-block;
  float: left;
}

.rs-loader-spin::before,
.rs-loader-spin::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  border-radius: 50%;
}

.rs-loader-spin::before {
  border: 3px solid rgba(247, 247, 250, 0.8);
}

.rs-loader-spin::after {
  border-width: 3px;
  border-style: solid;
  border-color: #a6a6a6 transparent transparent;
  -webkit-animation: loaderSpin 0.6s infinite linear;
  animation: loaderSpin 0.6s infinite linear;
}

.rs-loader-content {
  float: left;
  display: inline-block;
}

.rs-loader-spin+.rs-loader-content {
  margin-left: 12px;
}

.rs-loader-xs .rs-loader-spin+.rs-loader-content {
  margin-left: 10px;
}

.rs-loader-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  bottom: 0px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.9);
}

.rs-loader-vertical {
  width: auto;
  display: inline-block;
}

.rs-loader-vertical .rs-loader {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rs-loader-vertical .rs-loader-spin {
  margin: 0 auto;
}

.rs-loader-vertical .rs-loader-content {
  line-height: 1.66666667;
  text-align: center;
}

.rs-loader-vertical .rs-loader-spin,
.rs-loader-vertical .rs-loader-content {
  display: block;
  float: none;
}

.rs-loader-vertical .rs-loader-spin+.rs-loader-content {
  margin-left: 0;
  margin-top: 10px;
}

.rs-loader-inverse .rs-loader-content {
  color: #f7f7fa;
}

.rs-loader-inverse .rs-loader-backdrop {
  background: rgba(39, 44, 54, 0.83);
}

.rs-loader-inverse .rs-loader-spin::before {
  border-color: rgba(247, 247, 250, 0.3);
}

.rs-loader-inverse .rs-loader-spin::after {
  border-top-color: #fff;
}

.rs-loader-speed-fast .rs-loader-spin::after {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}

.rs-loader-speed-normal .rs-loader-spin::after {
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
}

.rs-loader-speed-slow .rs-loader-spin::after {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
}

.rs-loader-center,
.rs-loader-backdrop-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}

.rs-loader-center .rs-loader,
.rs-loader-backdrop-wrapper .rs-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.rs-loader-center:not(.rs-loader-vertical) .rs-loader,
.rs-loader-backdrop-wrapper:not(.rs-loader-vertical) .rs-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rs-loader-center.rs-loader-vertical .rs-loader,
.rs-loader-backdrop-wrapper.rs-loader-vertical .rs-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.rs-loader-center .rs-loader-content,
.rs-loader-backdrop-wrapper .rs-loader-content {
  z-index: 1;
}

.rs-loader-lg .rs-loader-spin {
  width: 64px;
  height: 64px;
}

.rs-loader-lg .rs-loader-spin::before,
.rs-loader-lg .rs-loader-spin::after {
  width: 64px;
  height: 64px;
}

.rs-loader-lg .rs-loader-content {
  font-size: 16px;
  line-height: 4;
}

.rs-loader-lg .rs-loader-vertical .rs-loader-lg .rs-loader {
  height: 94px;
}

.rs-loader-lg .rs-loader-vertical .rs-loader-lg .rs-loader-content {
  line-height: 1.25;
}

.rs-loader-md .rs-loader-spin {
  width: 36px;
  height: 36px;
}

.rs-loader-md .rs-loader-spin::before,
.rs-loader-md .rs-loader-spin::after {
  width: 36px;
  height: 36px;
}

.rs-loader-md .rs-loader-content {
  font-size: 14px;
  line-height: 2.57142857;
}

.rs-loader-md .rs-loader-vertical .rs-loader-md .rs-loader {
  height: 66px;
}

.rs-loader-md .rs-loader-vertical .rs-loader-md .rs-loader-content {
  line-height: 1.42857143;
}

.rs-loader-sm .rs-loader-spin {
  width: 18px;
  height: 18px;
}

.rs-loader-sm .rs-loader-spin::before,
.rs-loader-sm .rs-loader-spin::after {
  width: 18px;
  height: 18px;
}

.rs-loader-sm .rs-loader-content {
  font-size: 14px;
  line-height: 1.28571429;
}

.rs-loader-sm .rs-loader-vertical .rs-loader-sm .rs-loader {
  height: 48px;
}

.rs-loader-sm .rs-loader-vertical .rs-loader-sm .rs-loader-content {
  line-height: 1.42857143;
}

.rs-loader-xs .rs-loader-spin {
  width: 16px;
  height: 16px;
}

.rs-loader-xs .rs-loader-spin::before,
.rs-loader-xs .rs-loader-spin::after {
  width: 16px;
  height: 16px;
}

.rs-loader-xs .rs-loader-content {
  font-size: 12px;
  line-height: 1.33333333;
}

.rs-loader-xs .rs-loader-vertical .rs-loader-xs .rs-loader {
  height: 46px;
}

.rs-loader-xs .rs-loader-vertical .rs-loader-xs .rs-loader-content {
  line-height: 1.66666667;
}

.rs-loader-wrapper {
  display: inline-block;
  width: auto;
  line-height: 0;
}

.textareaform {
  resize: none;
}

@-webkit-keyframes loaderSpin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loaderSpin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* @-webkit-keyframes notificationMoveIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes notificationMoveIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes notificationMoveInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes notificationMoveInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes notificationMoveInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes notificationMoveInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes notificationMoveOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    max-height: 100px;
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}
@keyframes notificationMoveOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    max-height: 100px;
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
} */

/* RS-----LOADER END */
.rs-loader-spin:before {
  border: 3px solid #075ba9 !important;
}

.rs-loader-content {
  color: #075ba9 !important;
}

.rs-loader-wrapper {
  z-index: 9999;
}

.MuiInputBase-fullWidth {
  border: 1px solid #ddd;
}

.w-80 {
  width: 80%;
}

.w-80 input {
  width: 100%;
}

/* custom file upload css */
.custom-file-button input[type="file"] {
  margin-left: -2px !important;
}

.custom-file-button input[type="file"]::-webkit-file-upload-button {
  display: none;
}

.custom-file-button input[type="file"]::file-selector-button {
  display: none;
}

.custom-file-button:hover label {
  background-color: #dde0e3;
  cursor: pointer;
}

.custom-file-button .input-group-text {
  background: #027beb;
  color: #fff;
}

.custom-file-button .form-control:focus {
  box-shadow: none;
  outline: none;
}

/* custom file upload css ends*/
.upload_icon .deleteimageicon {
  position: absolute;
  top: -26px;
  left: 26px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: auto;
  bottom: 0;
  right: 0;
  color: #fff;
  font-size: 10px;
}

.upload_icon {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.upload_icon span img {
  padding: 10px;
}

.deleteIcon {
  width: 15px;
  height: 17px;
}

.upload_icon .deleteIcon {
  width: auto;
  height: auto;
  padding-top: 2px;
}

.nd-related-post::after {
  position: relative;
  content: ">";
  vertical-align: middle;
  line-height: 25px;
  font-size: 21px;
  display: inline;
  margin-left: 0.222em;
  margin-top: -0.111em;
  color: #2196F3;
}

.bg-grey {
  background-color: #f5f5f5 !important;
}

.newsdetail_screen .navbar {
  background-color: #fff !important;
}

.nd-related-post a {
  color: #2196F3 !important;
  cursor: pointer
}

.nd-main-content ul li {
  list-style-type: disc;
}

.autocomplete input {
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  padding: 0px 15px;
}

.autocomplete input:focus-visible {
  outline: none;
}

.autocomplete {
  height: 56px;
  z-index: 1
}

.autocomplete ul.suggestions {
  background: #fff;
  border: 1px solid #dddddd;
  border-top: 0px;
  padding: 0px;
  list-style: none;
}

.autocomplete ul.suggestions li {
  margin: 0px;
  padding: 5px 15px;
  cursor: pointer;
}

.autocomplete ul.suggestions li:hover {
  background: #f2f2f2;
}

.filterbannersearch .autocomplete ul.suggestions li {
  margin: 0px;
  padding: 5px 15px;
  cursor: pointer;
  text-align: left;
  color: #070f15;
}

.filterbannersearch .autocomplete {
  height: 50px;
  z-index: 1;
  width: calc(100% - 80px);
}

.filterbannersearch .input-group-text {
  background: transparent;
  width: 80px;
  border-left: 0px;
  justify-content: center;
  border-left: 0px;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  background: #005AAB;
  border: 1px solid #005AAB;
  color: #fff;

}

.dashboard_innerseacrch .customm_searchdiv {
  position: relative;
}

.dashboard_innerseacrch .customm_searchdiv input {
  background: transparent;
  padding-inline-end: 25px;
  height: 38px;
}

.dashboard_innerseacrch .customm_searchdiv::after {
  position: absolute;
  content: url('./Images/Icon-feather-search.svg');
  right: 10px;
  top: 9px;
}

.dashboard_right_fields .form-select {
  height: 40px;
  background-color: transparent;
  font-size: 15px;
}

.card.dashboard_cards {
  border: 0px !important;
  box-shadow: 0px 0px 6px #0000001C !important;
  border-radius: 5px;
  background: #fff;
}

.dashboard_cards .card-header {
  background: #fff;
  border-bottom: 0px;
  padding: 17px 20px;
}

.chart_icon_div .form-select {
  width: 150px;
}

.form-select {
  cursor: pointer;
}

.dashboard_cards .card-body {
  padding: 10px 20px 10px 20px;
}

.font_24 {
  font-size: 24px;
}

.font_55 {
  font-size: 55px;
}

.custom_popupp .modal-header {
  border-bottom: 0px;

}

.custom_popupp .modal-footer {
  border-top: 0px;
}

.astricksymbol {
  position: relative;
}

.astricksymbol::after {
  content: "*";
  position: absolute;
  top: 1px;
  left: -13px;
  color: red;
  font-size: 14px;
  font-weight: bold;

}

.aboutdevportal_Carousalmain .slick-dots {
  top: 0px !important;
  bottom: initial;
  display: flex !important;
}

.aboutdevportal_Carousalmain .slick-dots li {
  flex-direction: column;
  flex: 1;
  justify-content: space-evenly;
  background: #E2E2E2;
  pointer-events: none;
  margin: 0px;
  height: 5px;
  margin-right: 5px;
}

.aboutdevportal_Carousalmain .slick-dots li button {
  display: none;
}

.aboutdevportal_Carousalmain .slick-dots li.slick-active {
  background: #FCB61A;
}

.aboutdevportal_Carousalmain .slick-dots li:last-child {
  margin-right: 0px;
}

.slick-slide img {
  text-align: center;
  margin: 20px auto;
}

.dashboard_tiles {
  box-shadow: 0px 0px 6px #0000001C;
  padding: 20px;
  min-height: 100px;
  border-radius: 5px;
}

.dashboard_tile1 {
  background: #e3f3f7;
}

.dashboard_tile2 {
  background: #f7edd7;
}

.dashboard_tile3 {
  background: #dce0ec;
}

.dashboard_tile4 {
  background: #f1dddd;
}

.bg-footerblack {
  background: #434343;
  padding: 25px 0;
}

.mr-10 {
  margin-right: 10px;
}

.custom_group_btn .btn-check:checked+.btn-outline-primary {
  background: #005AAB;
}

.custom_group_btn .btn-outline-primary {
  border-color: #ced4da;
  color: #070F15;
  font-size: 14px;
  font-style: normal;
  min-width: 90px;
}

.dashboard_formfields .form-select {
  height: 38px;
}

.comments_main_divv {
  border-bottom: 1px solid #f2f2f2;
  padding: 20px 0;
}

.dashboard_cards .card-footer {
  background: #fff;
  border-top: 0px;
  padding: 10px 20px 10px 20px
}

.wasthishelpful_main_divv {
  border-bottom: 1px solid #f2f2f2;
  padding: 20px 0;
}

.greencolortxt {
  color: #4BC273;
}

.redcolortxt {
  color: #D41F63;
}

.dashboard_main_div .navbar,
.product_screen .navbar,
.faqEntryscreen .navbar {
  box-shadow: 0px 3px 5px #0000000D;
}

.swing:hover {
  transform-origin: top center;
  animation: swing 2s ease infinite;
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.hithere:hover {
  animation: hithere 1s ease infinite;
}

@keyframes hithere {
  30% {
    transform: scale(1.2);
  }

  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }

  50% {
    transform: rotate(20deg) scale(1.2);
  }

  70% {
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.pulse:hover {
  animation: pulse 1s ease-in-out alternate;
}

@keyframes pulse {
  from {
    transform: scale(1.1);
  }

  to {
    transform: scale(1);
  }
}

.custom_group_btn label {
  padding-top: 8px;
}

.color70 {
  color: #707070;
}

.size_15 {
  width: 18px;
  height: 18px;
}

.pad_top10 {
  padding-top: 10px;
}

.ReactTags__tagInputField {
  padding: 0px 15px !important;
  margin-top: 10px !important;
}

.ReactTags__tagInputField:focus-within {
  outline: none;
}

.ReactTags__selected .tag-wrapper.ReactTags__tag:first-child {
  margin-left: 0px;
}

.bg_blue_bg {
  background: rgb(65, 122, 246);
  border-radius: 50px;
  width: 50px;
  height: 50px;
  /* padding: 10px; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* .EventsJumboBanner {
  background: url('../../dev-portal/src/Images/event_top_bg.svg')left;
  background-repeat: no-repeat;
  background-position: left;
  background-position-y: -10px;
  background-color: #F9F9F9;
} */

.border-bot-grey {
  border-bottom: 1px solid #f2f2f2;
}

.bg_event_list {
  background: #fff;
  border-bottom: 1px solid #f2f2f2;
}

.eventslist li {
  display: inline-block;
  margin-bottom: 0px;
  margin-right: 25px;
  font-size: 14px;
  color: #070F15;
  cursor: pointer;
  padding-right: 10px;
  line-height: 16px;
  padding: 19px 0px 11px 0px;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  font-weight: 500;
  font-style: normal;
}

.eventslist li.active {
  border-bottom: 4px solid #005AAB;
  color: #005AAB;
}

.eventslist li:hover {
  color: #005AAB;
}

.datepicker__title {
  position: absolute;
  top: 27px;
  margin-left: 20px;
  font-weight: 400;
}

.Events_containerdiv {
  border: 1px solid #EEF0F4;
  padding: 25px 25px 55px 25px;
  transition: transform .2s;
  height: 100%;
  position: relative;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 4px 0.5px #f9f9f9;

}

.Events_containerdiv:hover {
  transform: scale(1.02);
  box-shadow: 0px 0px 27px 0px #dfdede;
  border-top: 3px solid #005AAB;
}

.favoritetxt {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: #070f15;
  cursor: pointer;
}

.favoritetxt:hover {
  color: #005AAB;
}

.bookmarkcolor {
  color: #070F15;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
}

.w-80 {
  width: 90%;
}

.View_more_button {
  position: absolute;
  bottom: 15px;
  left: 25px;
  font-size: 14px;
  cursor: pointer;
}

.View_more_button .nav-link {
  padding: 0px;
  color: #707070;
}

.single_host {
  width: 200px;
  margin: 0px auto;
  box-shadow: 0px 5px 20px #00000029;
}

/* .bg_container_host {
  background: url('../../dev-portal/src/Images/host_bg.svg');
  background-size: 100% 100%;
} */

.double_single_host {
  width: 200px;
  margin-right: 15px;
  box-shadow: 0px 5px 20px #00000029;
}

.triple_single_host {
  width: 160px;
  margin-right: 35px;
  box-shadow: 0px 5px 20px #00000029;
}

.aboutdevportal_Carousalmain.multihost {
  width: 250px;
  box-shadow: 0px 5px 20px #00000029;
}

.aboutdevportal_Carousalmain.multihost .single_host {
  box-shadow: none;
}

.single_host_icon_size {
  width: 116px;
  height: 116px !important;
  border-radius: 100%;
}

.triple_host_icon_size {
  width: 76px;
  height: 76px!important;
  border-radius: 100%;
}

ul.li_eventdetails li {
  list-style-type: disc;
}

.comment-form-button:disabled {
  opacity: 0.7;
  cursor: default;
}

.comment-form-button {
  font-size: 16px;
  padding: 8px 16px;
  background: rgb(59, 130, 246);
  border-radius: 8px;
  color: white;
  box-shadow: none;
  border: 0px;
  cursor: pointer;
}

.ww-80 {
  width: 70%;
}

.settingsdiv img {
  width: 30px;
  height: 30px;
}

.image_size_30 {
  width: 30px;
  height: 30px;
}

.bg_blue_bg img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.host_image {
  width: 45px;
  height: 45px;
  border-radius: 100%;
}

/* .host_image input[type="file"] {
  display: none;
} */
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 100%;
}

.rotate_upward {
  transform: rotate(180deg);
}

.blue_color {
  color: #005AAB;
}

.custom-dropzone {
  border: 1px dashed #E2E2E2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  border-radius: 5px;
}

.custom-dropzone button.btn-default {
  background: #eef0f4;
  color: #070f15;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.customeFileuploadLi {
  list-style-type: none;
}

/* .bg_event_banner{
  background-image: url('../src/Images/event_main_bg.png');
  background-size: cover;
  padding:50px 0 50px 0px;
   background-color: #fff; 
  position:relative;
} */
.bg_event_banner::after {
  width: 0;
  height: 0;
  border-top: 129px solid transparent;
  border-right: 1363px solid #fff;
  border-bottom: 0px solid transparent;
  position: absolute;
  content: '';
  bottom: 0px;
  right: 0px;
}

.ptop7030 {
  padding-top: 70px;
  padding-bottom: 0px;
}

.bg_blackk {
  box-shadow: none;
  padding: 30px 34px 30px;
}

.bg_blackk h3 {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.bg_blackk h3::first-letter {
  text-transform: capitalize;
}

.bg_blackk p {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.EventMainBannerCarouselDiv {
  width: 93%;
  float: right;
  box-shadow: 0px 3px 6px #00000014;
  position: relative;
  top: 85px;
  z-index: 9;
}

.EventMainBannerCarouselDiv .slick-slider {
  height: 100%;
  /* background: rgb(7, 91, 169,0.84); */
  background: rgba(255, 255, 255, 0.9);

}

.EventMainBannerCarouselDiv .slick-track {
  margin-left: inherit;
  margin-right: inherit;
}

.EventMainBannerCarouselDiv .thumbnail_slider .slick-slider {
  background: transparent;
}

.EventMainBannerCarouselDiv .bg_container_host {
  background-image: none;
}

.slightleft {
  position: relative;
  left: -3px;
}

.slightleft4 {
  position: relative;
  left: -6px;
}

.EventMainBannerCarouselDiv .slick-dots li button {
  width: 12px;
  height: 12px;
  border: 1px solid #CECECE;
  border-radius: 100%;
}

.EventMainBannerCarouselDiv .slick-dots li button:before {
  width: 12px;
  height: 12px;
  background: transparent !important;
  font-size: 0px;
  border-radius: 100%;
}

.EventMainBannerCarouselDiv .slick-dots {
  bottom: 15px;
}

.EventMainBannerCarouselDiv .slick-dots li.slick-active button:before {
  background: #FCB61A !important;
  opacity: 1;
}

.width_20px {
  width: 18px;
  height: 18px;
}

.tp_2 {
  position: relative;
  top: -2px;
}

/* .MuiDataGrid-row,
.MuiDataGrid-root .MuiDataGrid-cell,
.rendering-zone {
  max-height: none !important;
} */


.MuiDataGrid-root .MuiDataGrid-window {
  position: relative !important;
}

/* .MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
} */

/* .MuiDataGrid-root .MuiDataGrid-viewport {max-height: none !important;}
.MuiDataGrid-root{ height: auto !important;} */
.MuiDataGrid-root .MuiDataGrid-cell{
  padding:12px!important;
}
.dashboard_formfields .wrapper {
  z-index: 99 !important;
}

.Dashboard_tabs ul li {
  display: inline-block;
  list-style: none;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  margin-right: 20px;
  border-bottom: 3px solid transparent;
  padding: 6px 0 3px 0;
  cursor: pointer;
}

.Dashboard_tabs ul {
  padding: 0px;
  margin: 0px;
}

.dashboard_formfields .wrapper .jMcrRR {
  min-height: 38px !important;
}

.w-30 {
  width: 30%;
}

.Dashboard_tabs ul li.active {
  display: inline-block;
  list-style: none;
  font-weight: 500;
  font-style: normal;
  border-bottom: 3px solid #005AAB;
}

.host_image>.addbuttonclass {
  display: none;
}

.host_image>.custom-file-upload .addbuttonclass {
  display: none;
  position: absolute;
  width: 45px;
  height: 45px;
  background-color: #33333380;
  transform: rotate(45deg);
  border-radius: 50%;
  z-index: 999991;
}

.host_image:hover>.custom-file-upload .addbuttonclass {
  display: inline-block;
}

.bookmarkstatic {
  background: url('../src/Images/icon_fav_static.svg');
  width: 15.212px;
  height: 18.986px;
  cursor: pointer;
}

.bookmarkstatic.SearchRed {
  background: url('../src/Images/icon_fav_active.svg') !important;
  border: none !important;
}
.thumbsup_static{
  background: url('../src/Images/thumbsup_static.svg');
  width: 16.02px;
  height: 16.26px;
  cursor: pointer;
}
.thumbsup_static.active{
  background: url('../src/Images/thumbsup_active.svg');
  width: 15.02px;
  height: 15.26px;
  cursor: pointer;
  border: none !important;
}
.thumbsdown_static{
  background: url('../src/Images/thumbsdown_static.svg');
  width: 16.02px;
  height: 16.26px;
  cursor: pointer;
}
.thumbsdown_static.active{
  background: url('../src/Images/thumbsdown_active.svg');
  width: 15.02px;
  height: 15.26px;
  cursor: pointer;
  border: none !important;
}
.form-switch .form-check-label {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
}

.comment .comment-right-part .flex-grow-1 p {
  word-break: break-word;
}

.carousal_maindiv1 .d-flex .flex-grow-1 p {
  word-break: break-word;
}

.owl-carousel .owl-item img {
  display: block;
  width: auto !important;
  margin: 0px auto !important;
}

#commentsscroll {
  margin-bottom: 0px;
}

.fav_static {
  padding: 10px 0px;
  cursor: pointer;
}

.fav_active {
  background: #f3f3f3;
  padding: 10px 10px;
  cursor: pointer;
}

.fav_active .favoritetxt {
  color: #005AAB;
}

a .Events_containerdiv h4 {
  color: #070f15 !important;
}

.bg_container_host p {
  word-break: break-word;
}

:root .datepickerCustom .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.7em !important;
}

:root .datepickerCustom .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -2px !important;
  font-size: 13px !important;
}

:root .datepickerCustom .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 10px;
}

:root .datepickerCustom .MuiFormControl-root {
  margin-bottom: 10px !important;
  width: 47% !important;
  margin-right: 6px !important;
}

:root .datepickerCustom .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 12px;
}

:root .css-1uvydh2 {
  height: 0.7em !important;
}

.css-1uvydh2 {
  height: 0.7em !important;
}

.css-p0rm37 {
  font-size: 14px;
  top: -2px !important;
}

:root .css-p0rm37 {
  font-size: 14px;
  top: -2px !important;
}

.mt_80 {
  margin-top: 70px;
}

.borderradius25 {
  border-radius: 25px !important;
}

button.Btn-Custom-Primary {
  color: #fff !important;
  background: #005aab;
  border: 1px solid #fff;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  padding: 9px 25px 9px 25px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

button.Btn-Custom-Primary:hover {
  color: #005aab !important;
  background: #fff;
  border: 1px solid #005aab;
}

button.Btn-Custom-Primary_Small {
  color: #fff !important;
  background: #005aab;
  border: 1px solid #fff;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  padding: 8px 15px 6px 15px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

button.Btn-Custom-Primary_Small:hover {
  color: #005aab !important;
  background: #fff;
  border: 1px solid #005aab;
}

.bg_grey_f8f8f8 {
  background: #fafafa;
}

.iconn_bg_blue {
  background: #53ACF5;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconn_bg_pink {
  background: #D95988;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg_orangelite {
  background: rgb(252, 182, 26, 0.05);
}

.bg_bluelite {
  background: rgb(0, 90, 171, 0.05);
}

.bg_blueee {
  background: #e9f0f7;
}

.linee_bg {
  background-image: url(../src/Images/slider_rapidx_line1.svg);
  position: absolute;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  height: 100%;
  width: 295px;
  margin: 0px auto;
  z-index: 9;
  top: 8%;
}

.bg_Blue {
  background: #005AAB;
}

.bgbackground_linebg {
  border-radius: 20px;
  background-image: url('../src/Images/bgbackground_linebg.png');
}
.mle-7{
  margin-left:-7%;
}
button.Btn-Custom-Secondary {
  color: #005aab !important;
  background: #fff;
  border: 1px solid #005aab;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  padding: 9px 25px 9px 25px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

button.Btn-Custom-Secondary:hover {
  color: #fff !important;
  background: #005aab;
  border: 1px solid #fff;
}

button.Btn-Custom-Secondary_Small {
  color: #005aab !important;
  background: #fff;
  border: 1px solid #005aab;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  padding: 8px 15px 6px 15px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

button.Btn-Custom-Secondary_Small:hover {
  color: #fff !important;
  background: #005aab;
  border: 1px solid #fff;
}

.Triple_host .triple_single_host:nth-child(2) {
  margin-bottom: 50px !important;
  margin-top: 0px !important;
}

.custom-search-p {
  padding: 10px 5px 8px 30px;
  border: 1px solid #ddd;
  border-radius: 25px;
}

.comments_main_divv .d-flex .flex-grow-1 p {
  word-wrap: break-word;
}

.Slider_rapidx_div .slick-prev:before {
  content: url('../src/Images/Icon_arrow_left.svg');
  cursor: pointer;
}

.Slider_rapidx_div .slick-next:before {
  content: url('../src/Images/Icon_arrow_right.svg');
  cursor: pointer;
}

.bg_container_host a {
  color: #070F15 !important;
  text-decoration: underline;
}

button.Tickbtn {
  padding: 0px;
  margin: 0px;
  border: 0px;
  box-shadow: 0px;
}

.EventMainBannerCarouselDiv .slick-dots li {
  margin: 0px 2px;
}

.bg_container_host code {
  color: #fff !important;
}

.arrow_yellow {
  position: absolute;
  top: 38%;
  left: 72px;
  animation: bounceee 2s ease infinite;
}

@keyframes bounceee {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-90px);
  }

  60% {
    transform: translateY(0px);
  }
}

.arrow_red {
  position: absolute;
  top: 23.5%;
  left: 54%;
  animation: bounceee1 2s ease infinite;
}

@keyframes bounceee1 {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(50px);
  }

  60% {
    transform: translateX(0);
  }
}

.arrow_green {
  position: absolute;
  top: 52%;
  left: 73%;
  animation: bounceee2 2s ease infinite;
}

@keyframes bounceee2 {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(0px);
  }

  60% {
    transform: translateX(-90px);
  }
}

.grey_triangle {
  position: absolute;
  left: -8%;
  top: 55%;
  /* animation: hithere2 1s ease infinite; */
}

@keyframes hithere2 {
  30% {
    transform: scale(1.2);
  }

  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }

  50% {
    transform: rotate(20deg) scale(1.2);
  }

  70% {
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.greyred_triangle {
  position: absolute;
  bottom: 0%;
  left: 10%;
  /* animation: hithere3 1s ease infinite; */
}

@keyframes hithere3 {
  30% {
    transform: scale(1.2);
  }

  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }

  50% {
    transform: rotate(20deg) scale(1.2);
  }

  70% {
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.green_circle {
  right: 9.5%;
  position: absolute;
  top: 26%;
  animation: gelatine 1s infinite;
}

@keyframes gelatine {

  from,
  to {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(0.9, 1.1);
  }

  50% {
    transform: scale(1.1, 0.9);
  }

  75% {
    transform: scale(0.95, 1.05);
  }
}

.yellow_rect {
  position: absolute;
  top: 24%;
  left: -6%;
  /* animation: hithere4 1s ease infinite; */
}
.yellow_rect1{
  position: absolute;
  top: 0%;
  right: 0%;
}
.yellow_rect2{
  left: -74px;
    top: 0%;
    position: absolute;
}

@keyframes hithere4 {
  30% {
    transform: scale(1.2);
  }

  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }

  50% {
    transform: rotate(20deg) scale(1.2);
  }

  70% {
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.red_rect {
  position: absolute;
  top: 31%;
  left: -18%;
  /* animation: pulse 1s infinite ease-in-out alternate; */
}

@keyframes pulse {
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1.05);
  }
}

.border-left-1 {
  border-left: 1px solid #ddd;
  line-height: 22px;
}

.w-85px {
  width: 85px;
}

:root .MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

button.Button_outline_secondary {
  color: #393F44 !important;
  background: #fff;
  border: 1px solid #848484;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  padding: 6px 25px 7px 25px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

button.Button_outline_secondary:hover {
  color: #fff !important;
  background: #6c757d;
  border: 1px solid #6c757d;
}

button.Button_primary {
  color: #fff !important;
  background: #005AAB;
  border: 1px solid #005AAB;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  padding: 9px 25px 7px 25px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

button.Button_primary:hover {
  color: #fff !important;
  background: #027beb;
  border: 1px solid #027beb;
}

.form-select {
  height: 45px;
}

.sl_no {
  margin-right: 13px;
  width: 15px;
}

.editdelete_icons {
  display: none;
}

.View_QA .show_editdelete_icons:hover .editdelete_icons {
  display: block;
}

.bdHMCv>.wrapper {
  z-index: 9 !important;
  ;
}

.pHieT>ul>li {
  cursor: pointer;
}

.pulse_hover:hover {
  transform: scale(1.05);
}

.cyevkj>ul>li {
  cursor: pointer;
}

.bocPQh>.wrapper {
  z-index: 9 !important;
}

.Thumbnail_div {
  height: 45px;
  display: flex;
  align-items: center;
  border: 1px solid #D3D3D3;
  background-color: #fff;
  margin: 0px auto;
  margin-right: 5px;
}

.thumbnail_slider {
  margin: 20px 0;
}

.thumbnail_slider .slick-slider {
  width: 100%;
}

.thumbnail_slider .slick-slider {
  margin: 10px auto;
  margin-bottom: 0px;
  z-index: 999;
}

.Thumbnail_main img {
  margin: 0px auto;
}

.modal_image_main.slick-slider .slick-prev {
  left: -75px !important;
}

.modal_image_main.slick-slider .slick-next {
  right: -50px !important;
}

.modal_image_main.slick-slider .slick-prev:before {
  content: url('../src/Images/slick_slider_right_icon.svg') !important;
}

.modal_image_main.slick-slider .slick-next:before {
  content: url('../src/Images/slick_slider_left_icon.svg') !important;
}

.autocomplete ul.suggestions li.active {
  background: #f2f2f2;
}

.thumbnail_slider .slick-slide.slick-active.slick-cloned {
  display: none;
}

.width85 {
  width: 85% !important;
}

pre {
  white-space: pre-wrap !important;
  font-size: 14px !important;
  font-family: "Heebo" !important;
}

.ptop9060 {
  padding-top: 90px;
  padding-bottom: 60px;
}

a.newsTitle {
  color: #070F15;
  text-decoration: none;
}

a.newsTitle:hover {
  color: #005aab;
  text-decoration: underline;
}

.fixHidden {
  cursor: pointer;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 100px !important;
  min-height: 175px !important;
}

.thumbnail_slider .slick-slide img {
  text-align: center;
  margin: 0px auto !important;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.about_devportal_editdiv {
  border: 1px solid #EEF0F4;
  border-radius: 10px;
  padding: 17px 30px;
}

.about_devportal_addNew {
  border: 1px solid #EEF0F4;
  border-radius: 10px;
  padding: 17px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.EventMainBannerCarouselDiv .slick-dots {
  width: auto !important;
  left: 30px;
}

.modal-backdrop.show {
  opacity: .3 !important;
}

.EventMainBannerCarouselDiv .thumbnail_slider .slick-prev:before {
  content: url('/src/Images/icon_left_arrow_slider.svg')
}

.EventMainBannerCarouselDiv .thumbnail_slider .slick-next:before {
  content: url('/src/Images/icon_right_arrow_slider.svg')
}

.EventMainBannerCarouselDiv .thumbnail_slider .slick-prev {
  left: -30px;
}

.EventMainBannerCarouselDiv .thumbnail_slider .slick-next {
  right: -30px;
}

/* .MuiDataGrid-footerContainer {
  border-top: 0px !important;
} */

pre code {
  font-family: "Heebo" !important;
  font-size: 14px;
}

.MuiDataGrid-panelWrapper .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 0px !important;
  border: 0px !important;
}

.MuiDataGrid-panelWrapper .css-1h08ml-MuiFormControl-root-MuiDataGrid-filterFormValueInput {
  justify-content: flex-end !important;
}

.MuiDataGrid-panelWrapper .css-1l6di18 .MuiOutlinedInput-root {
  padding: 0px !important;
  border: 0px !important;
}

.MuiDataGrid-panelWrapper .css-1tx315l {
  justify-content: flex-end !important;
}

.placeholder_loading {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1500;
  background: #fff;
}

.pt_130 {
  padding-top: 130px;
}

.pt_108 {
  padding-top: 108px;
}

.pt_150 {
  padding-top: 150px;
}

.pt_100 {
  padding-top: 100px;
}

.pt_170 {
  padding-top: 170px;
}

.pt_120 {
  padding-top: 120px;
}

.pt_180 {
  padding-top: 180px;
}

.warning_message {
  font-size: 13px;
  font-style: italic;
  color: #2196f3;
}

.warning_message_1 {
  font-size: 13px;
  font-style: italic;
  margin-top: 15px;
  color: #2196f3;
}

.userProfileImage {
  display: inline-block;
  padding: 12px 13px;
  border-radius: 50%;
  font-weight: 500;
  font-family: inherit;
  font-size: 20px;
  margin-right: 5px;
}

.font_26 {
  font-size: 26px;
}

.rapidx_landingscreen {
  background: url(../src/Images/banner_rapix_bg.png) right;
  background-repeat: no-repeat;
  /* background-size: 100% 100%; */
   background-position: right;
  background-color:#fff;
  background-position-y:-20px;
  background-color: #fff;
}

.ptop90 {
  padding-top: 90px;
  padding-bottom: 20px;
}

.font_55 {
  font-size: 55px;
}

.font_40 {
  font-size: 40px;
}

.color_blu {
  color: #50BDFF;
}

.ml_13minus {
  margin-left: -13%;
}

.font_42 {
  font-size: 42px;
}

.Slider_rapidx_div .slick-prev {
  left: -8%;
}

.Slider_rapidx_div .slick-next {
  right: -8%;
}

.Slider_rapidx_innerdiv p {
  position: relative;
  overflow: visible;
  text-align: center;
  padding: 25px 60px;
}

.Slider_rapidx_innerdiv p::before {
  position: absolute;
  background: url(../src/Images/quote_icon_top.svg)no-repeat;
  content: '';
  width: 37px;
  height: 33px;
  left: 0;
}

.Slider_rapidx_innerdiv p::after {
  position: absolute;
  background: url(../src/Images/quote_icon_bottom.svg)no-repeat;
  content: '';
  width: 37px;
  height: 33px;
  right: 0;
}

.pb_80 {
  padding-bottom: 80px;
}

/* chatBox Css starts */
.chat_bot{
  height:calc(82vh - 50px)!important;
}
.chatbot_icon {
  position: fixed;
  bottom: 25px;
  right: 25px;
  background: #429AEB 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000029;
  border: 4px solid #EAEAEA;
  width: 60px;
  height: 60px;
  padding:5px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index:999;
}

@media (min-width: 820px) {
  .chatbox_small {
    bottom: 25px;
    width: 360px;
    max-width: 720px;
    max-height: calc(90vh - 50px);
    position: fixed;
    right: 20px;
    z-index: 99999;
    background: #fff;
    box-shadow: 0px 10px 50px #0000001A;
    border-radius: 10px;

  }

  .chatbox_small.Expand {
    box-shadow: 0 1px 60px 0 rgb(41 52 67 / 45%);
    position: absolute;
    right: 25px;
    bottom: 110px;
    width: 85%;
    height: 350px;
    border-radius: 10px;
    font-size: 14px;
    background-color: #fff;
    transition: width 120ms, height 120ms;
  }
}

.chatbox_small.Expand {
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
  transition: transform 120ms, width 120ms, height 120ms;
}

.chatbox_small.Expand {
  width: 80%;
  max-width: 720px;
  height: 83vh;
  top: 70px;
  position: fixed;
}

.chat_header {
  background: #429AEB;
  color: #fff;
  height: 50px;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chat_footer {
  width: 100%;
  height: 58px;
  box-shadow: 0px 10px 50px #0000001A;
  border-radius: 0px 0px 10px 10px;
}

.embed_placeholder {
  width: 100%;
}

.chatinput_text input {
  height: 45px;
  border: 0px;
  width: 90%;
  color: #070F15;
}

.chatinput_text input:focus {
  box-shadow: none;
  outline: none;
}

.backdrop_chat {
  background: rgba(112, 112, 112, 0.5);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index:9999;
}

@media (max-width: 767px) {
  .chatbox_small.Expand {
    width: 100vw;
    height: 82vh;
    position: fixed;
    top: 0px;
    z-index: 9999;
    background: #fff;
  }

  .chatbox_small {
    width: 100vw;
    height: 82vh;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 9999;
    background: #fff;
  }
}

/* chatBox Css Ends */
.codegen_container {
  border: 1px solid #707070;
  padding: 20px 40px 40px 20px;
  border-radius: 5px;
}

.codegetul li {
  display: inline-block;
  padding: 3px 10px 0px 10px;
  background: #EEF0F4;
  margin-right: 10px;
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  cursor: pointer;
}

.codegetul li:hover,
.codegetul li.active {
  background: #075BA9;
  color: #fff;
}

.codeblock_container {
  background: #4E4E4E;
  border-radius: 5px;
  padding: 20px 18px;
}

.hrdashedline {
  border-bottom: 1px dashed #E2E2E2;
}

.custom_txtareea textarea {
  border: 0px;
  width: 100%;
  padding: 20px;
  resize: none;
}

.custom_txtareea textarea:focus-visible {
  outline: 1px solid #ddd;
}

.custom_txtareea textarea::placeholder {
  color: #B4B7B8;
  opacity: 1;
}

.generate_div_height {
  height: calc(65% - 50px);
  background: #fff;
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  background: #fff;
  overflow-y: auto;
}

.explanation_div_height {
  height: calc(42% - 45px);
  margin-top: 20px;
  background: #F2EED1;
  background: #F2EED1;
  padding: 20px;
  border-radius: 5px;
  overflow-y: auto;
}

button.button_grey_small {
  padding: 3px 10px 0px 10px;
  background: #EEF0F4;
  margin-right: 10px;
  border: 1px solid #E2E2E2;
  font-size: 14px;
  font-weight: regular;
}

button.button_grey_small:hover {
  background: #075BA9;
  color: #fff;
}

.codegen_rightarrow {
  position: absolute;
  top: 45%;
  right: -22px;
  border: 1px solid #E3E3E3;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #fff;
}

.ellipse {
  top: 63px;
left: 885px;
width: 16px;
height: 16px;
background: #E6B23D 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
opacity: 1;
}

.green_ellipse {
  display: inline-block;
  right: 0px;
  top: 50%;
  position: absolute;
}
  .yellow_rect3 {
    position: absolute;
    top: 38%;
    left: 5%;
}
.yellow_rect4 {
  position: absolute;
  bottom: 20%;
  right: 5%;
}

.greenEllipse {
width: 16px;
height: 16px;
/* background: transparent url('img/Ellipse 13.png') 0% 0% no-repeat padding-box; */
box-shadow: 0px 3px 6px #00000029;
opacity: 1;
background-color: #43B97F;
    display: inline-block;
    border-radius: 50%;
}
.custom_search_input .input-group{
align-items: center;
}
.custom_search_input .input-group input{
  padding-right: 30px!important;
    border-right: 1px solid #ddd!important;
    border-top-right-radius: .25rem!important;
    border-bottom-right-radius: .25rem!important;
}
.custom_search_input .input-group .input-group-append{
  position: absolute;
  right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.devtool_upload_icon{
  width:45px;
  height:45px;
  border-radius: 0.25rem;
  border:1px solid #ddd;
}
textarea,textarea.form-control{
  resize: none!important;
}
iframe ~ div:focus-visible{
  outline: none!important;
}
div iframe ~ div:focus-visible{
  outline: none!important;
}
iframe + div:focus-visible{
  outline: none!important;
}
iframe div:focus-visible{
  outline: none!important;
}
iframe > div:focus-visible{
  outline: none!important;
}
.mt_70{
  margin-top:70px;
}
.bannerbg_myprofile{
  background-image: url('../src/Images/myprofilebannerbg.png');
  height:120px;
}
.myprofile_progreebar .progress{
  height:10px;
  border-radius: 5px;
  background: #D9D9D9;
}
.myprofile_progreebar .progress .progress-bar{
  background: #72B266;
}
.profile_icon_div{
  position: relative;
  top:-40%;
  width:150px;
  height:150px;
  border-radius: 100%;
  border:4px solid #fff;
  background: #d2d2d2;
}
.skillsulli{
padding: 0px;
}
.skillsulli li{
  border:1px solid #707070;
  display: inline-block;
  padding:5px 20px;
  border-radius: 50px;
  font-size:16px;
  font-weight: normal;
  margin-right: 15px;
}
.badgesUL li{
  display:inline-block;
}
.badgesUL li{
  margin-right:15px;
}
.badgesUL{
  padding-left:0px;
}
.addbtninputt{
  position: relative;
}
.addbtninputt input{
  position:relative;
}
.inputcheckiconmain{
  position:relative;
}
.inputcheckicon{
  position: absolute;
    right: 10px;
    top: 12px;
  cursor:pointer;
}
.file_upload_profileicon{
  position: absolute;
    right: 0px;
    bottom: 0%;
    background: #005AAB;
    border: 2px solid #fff;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.desktop_progress_align{
  margin-left:-4%;
}
.myprofilesearchul li{
  list-style: none;
  margin-bottom: 10px;
  font-size: 14px;
}
.myprofilegridviewdiv{
  padding:15px 20px;
  background: #fff;
  border:1px solid #EEF0F4;
  border-radius: 10px;
  position:relative;
  padding-bottom:60px;
  cursor:pointer;
  transition: transform .2s;
  border-top:3px solid #005AAB;
}
.myprofilegridviewdiv:hover{
  box-shadow: 0px 30px 50px #0000000D;
  background: #fff;
  transform: scale(1.03);
}
.search_profilepic{
  width:60px;
  height:60px;
  border-radius:100%;
  border: 3px solid #DBDBDB;
}
.foot_seachgrid{
  position: absolute;
  bottom: 15px;
  left: 0px;
  right: 0px;
  margin: 0px 20px;
}
.listview .foot_seachgrid{
  position:relative!important;
}
.listview .myprofilegridviewdiv{
  padding-bottom:15px;
}
.listview .myprofilegridviewdiv:hover{
  transform: scale(1.03);
}
.profile_icon_div.popup{
  top: 0% !important;
  width:104px;
  height:104px;
}
.profile_icon_div.popup .file_upload_profileicon{
right:-10px;
}
/* layout compoment */
.navbar .navbar-brand-wrapper {
  transition: width .25s ease,background .25s ease;
  -webkit-transition: width .25s ease,background .25s ease;
  -moz-transition: width .25s ease,background .25s ease;
  -ms-transition: width .25s ease,background .25s ease;
  background: #fff;
  width: 260px;
  height: 70px;
}
.navbar .navbar-menu-wrapper {
  transition: width .25s ease;
  -webkit-transition: width .25s ease;
  -moz-transition: width .25s ease;
  -ms-transition: width .25s ease;
  color: #9c9fa6;
  padding-left: 24px;
  padding-right: 24px;
  width: calc(100% - 260px);
  height: 70px;
}
.navbar.fixed-top+.page-body-wrapper {
  padding-top: 70px;
}

.page-body-wrapper {
  min-height: calc(100vh - 70px);
  padding-top: 70px;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
}
.sidebar {
  min-height: calc(100vh - 70px);
  background: #fff;
  width: 261px;
  padding:0px 15px;
  z-index: 11;
  transition: width .25s ease,background .25s ease;
  -webkit-transition: width .25s ease,background .25s ease;
  -moz-transition: width .25s ease,background .25s ease;
  -ms-transition: width .25s ease,background .25s ease;
}
.main-panel {
  -webkit-transition: width .25s ease,margin .25s ease;
  transition: width .25s ease,margin .25s ease;
  width: calc(100% - 261px);
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
}
button.navbar-toggler:focus{
  box-shadow:none;
}
.sidebar .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
}
.sidebar .nav a{
  color:#68728C;
  font-size:14px;
  font-weight: normal;
  font-style: normal;
  cursor:pointer;
  text-decoration: none!important;
  padding:10px;
}
.sidebar .nav .nav-item:hover{
  background: #005AAB;
  color:#fff!important;
}
.sidebar .nav .nav-item:hover a{
  color:#fff!important;
}
.sidebar .nav .nav-item.active a{
  color:#fff;
  font-weight: 500;
  font-style: normal;
  padding: 10px 10px;
  text-decoration: none!important;
}
.submenu_items{
  margin-left:35px;
}
.submenu_items li a{
  padding-left:0px;
  padding:5px 0;
}
ul.navbar-nav li{
  margin-left:15px;
}
.content-wrapper {
  background: #fff;
  padding: 2rem 2rem;
  width: 100%;
  flex-grow: 1;
  padding-bottom:0px;
}
.sidebar .nav .nav-item {
  -webkit-transition-duration: .25s;
  transition-duration: .25s;
  transition-property: background;
  -webkit-transition-property: background;
  border-radius: 5px;
  margin-bottom:5px;
}
.sidebar .nav .nav-item .nav-link{
  -webkit-transition-duration: .25s;
  transition-duration: .25s;
  transition-property: background;
  -webkit-transition-property: background;
}
/* layout component ends */
.profile_icon_div img{
  width:100%;
  height:100%;
  border-radius: 100%;
}
.profile_icon_div .deleteimageicon{
  top: 35%;
    left: 35%;
}
.custom_search_input.profilescreen .input-group-append{
  border: 1px solid #ddd;
    border-left: 0px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
}
.custom_search_input.profilescreen{
  position: relative;
}
.custom_search_input.profilescreen .suggestions{
  position: absolute;
    width: 100%;
    z-index: 999;
    background: #fff;
    padding: 0px;
}
.custom_search_input.profilescreen ul.suggestions li{
  list-style: none;
    padding: 5px 10px;
    border:1px solid #ccc;
}
.custom_search_input.profilescreen ul.suggestions li:hover{
  background: #ddd;
}
.search_profilepic img{
  width: 100%;
  height:100%;
  border-radius: 100%;
}
.myprofilegridviewdiv a{
  color:#070F15;
}
.myprofilegridviewdiv a:hover{
  color:#070F15;
}
.sidebar_toggle .sidebar.sidebar-offcanvas{
  width:0px!important;
  padding: 0px;
  border-right: 0px!important;
}
.sidebar_toggle .sidebar.sidebar-offcanvas > p{
display: none;
}
.sidebar_toggle .sidebar.sidebar-offcanvas > ul{
  display: none;
  }
  .sidebar_toggle .navbar-brand-wrapper{
    width:50px;
    border-bottom: 1px solid #ddd;
  }
  .sidebar_toggle .navbar-menu-wrapper{
    width: calc(100% - 50px);
    border-left:0px!important;
    padding-left: 10px;
  }
  .sidebar_toggle .main-panel{
  width: 100%;
  }
  .sidebar_toggle .main-panel .content-wrapper{
    padding:1rem 1rem;
    padding-bottom:0px;
  }
  @media only screen and (max-width: 767px) {
  }
  .ForumMaincontainer{
    background: url(../src/Images/forum_bg.svg) center;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: -3px;
}
.custom_inputbtn input{
  width:100%;
  border-radius:25px;
  position:relative;
  padding:12px 34px 12px 20px!important;
}
.custom_inputbtn{
  position:relative;
  max-width:350px;
}
.searchicon{
  position:absolute;
  top:9px;
  right:20px;

}
.forum_div{
  border:1px solid #EEF0F4;
  padding:20px;
  border-radius: 10px;
  transition: transform .2s;
}
.forum_div:hover{
  transform: scale(1.03);
  box-shadow: 0px 0px 27px 0px #dfdede;
}

.forum_div .nav-link{
  color: #070f15;
}
.forum_div .nav-link:hover{
  color: #070f15;
}
.button_Grey_small{
  background: #EEF4F4!important;
  font-size:12px!important;
  color:#070F15!important;
  padding: 3px 10px!important;
  border:1px solid #E2E2E2!important;
  margin-left:10px;
}
.button_blue_small{
  background: #A9ECF2!important;
  font-size:12px!important;
  color:#070F15!important;
  padding: 3px 10px!important;
  border:1px solid #E2E2E2!important;margin-left:10px;
}
.button_discussion_small{
  background: #F2EAA9!important;
  font-size:12px!important;
  color:#070F15!important;
  padding: 3px 10px!important;
  border:1px solid #E2E2E2!important;margin-left:10px;
}
.button_resolved_small{
  background: #B9EFB1!important;
  font-size:12px!important;
  color:#070F15!important;
  padding: 3px 10px!important;
  border:1px solid #E2E2E2!important;margin-left:10px;
}
.forum_image{
  width:36px;
  height:36px;
  min-width:36px;
  min-height:36px;
  border:3px solid #DBDBDB;
  border-radius: 100%;
}
.color_blue{
  color:#005AAB;
}
.chipbtn{
  background: #f2f2f2;
  display: inline-block;
}
.codebg{
  background: #DEDEDE;
  padding:20px;
  border-radius: 5px;
}
.custom_search_input .SearchRed{
  border-top-left-radius:25px;
  border-bottom-left-radius:25px;
}
.myprofilegridviewdiv a.nav-link{
  padding:0px;
}
.w-300{
  width:300px;
}
.sidebar .nav .nav-item.active{
  background: #005AAB;
  color:#fff;
}
.ptop030{
  padding-top:0px;
  padding-bottom: 30px;
}
.navcustomlink{
  display: flex;
    padding: 0px!important;
    align-items: center;
}
.nav-item.dropdown a{
 color:#070F15;
}
.filterbannersearch.forum .autocomplete input{
  border-top-right-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
}
.filterbannersearch.forum .autocomplete ul.suggestions{
  list-style: none;
}
.forum_image img{
  border-radius: 100%;
  background-size: 100%;
}
.react-confirm-alert-overlay{
  z-index:9999!important;
}
.react-confirm-alert-body{
  box-shadow: 2px 5px 27px 0px #f2f2f2!important;
    border: 1px solid #ddd!important;
}
.react-confirm-alert-body > h1{
  font-size: 25px!important;
  margin-bottom: 14px!important;
}
.react-confirm-alert-button-group{
  justify-content: flex-end!important;
}
.react-confirm-alert-button-group > button[label ="Yes"]{
  background: #005AAB!important;
}
.react-confirm-alert-button-group > button[label ="No"]{
  background: transparent!important;
  border:1px solid #1E1E1E!important;
  color:#070F15!important;
}
.multiSelectContainer .optionListContainer {
  z-index: 99 !important;
}
.beingdiscussed .forum_div{
  border-top-width:3px;
  border-top-color: rgb(231, 125, 79);
}
.open .forum_div{
  border-top-width:3px;
  border-top-color: rgb(65, 122, 246);
}
.resolved .forum_div{
  border-top-width:3px;
  border-top-color: #4BB543;
}
.width_1518{
  width:15px;
  height:18px;
}
.webchat__basic-transcript__filler{
  display: none!important;
}
.webchat__bubble__content{
  min-height:auto!important;
}
.webchat__bubble__content p.plain{
  padding:5px 10px;
}
.webchat__bubble__content .markdown {
  padding:5px 10px;
  background: #badeff;
}
.webchat__basic-transcript__scrollable:focus{
outline: none!important;
}
.webchat__basic-transcript__scrollable:focus-within{
  outline: none!important;
  }
.webchat__basic-transcript__focus-indicator{
outline:none!important;
border:0px!important;
  }
  .webchat__basic-transcript__focus-indicator:focus,.webchat__basic-transcript__focus-indicator:focus-visible,.webchat__basic-transcript__focus-indicator:focus-within{
    outline:none!important;
    border:0px!important;
      }
  .webchat__send-icon{
    fill:#429AEB;
  }
  /* .devportal_marketplacescreen{
    background: url(./app/common/images/marketplacebg.svg) left;
    background-repeat: no-repeat;
    background-position: left;
    background-position-y: -10px;
    background-color: #F9F9F9;
} */

.marketplace .forum_div:hover{
  border-bottom: 2px solid #005AAB;
}
.m-70{
  margin-top:70px;
}
.marketplaceBanner{
  background:url('../src/Images/marketwave_bg.svg')#075BA9;
}
.marketplace .forum_div{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #00000027;
  border-radius: 10px;
  border: none;
  position: relative;
  padding-bottom: 50px;
}
.mx-15{
  margin:0px -15px;
}
.botdivdownload{
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 10px 15px;
}
.react-toast-notifications__container{
  z-index:9999999!important
}
.navbar-brand{
  display: flex;
  align-items: center;
}